import { gql } from "@apollo/client";
import { useUIState } from "@contexts/UIStateProvider";
import { CohortSpecialDaysCard_CohortFragment } from "@generated/graphql";
import { SpecialDaysTooltip } from "@shared/SpecialDays/SpecialDaysTooltip";
import { SpecialDayType } from "@shared/SpecialDays/types";
import { daysDetails, getSpecialDaysDetails } from "@shared/SpecialDays/utils";
import { Card } from "components/shared";
import { HeaderColors, HeightMode } from "components/shared/Card";
import {
  SpecialDaysModal,
  SpecialDaysScrollContainer,
} from "components/shared/SpecialDays";
import { normalize } from "components/shared/SpecialDays/helpers";
import { useEffect, useMemo, useState } from "react";
import { DetailsRowStyles } from "types/global";

export const COHORT_SPECIAL_DAYS_KEY = "CohortSpecialDaysCard";

CohortSpecialDaysCard.fragments = {
  cohort: gql`
    fragment CohortSpecialDaysCard_Cohort on Cohort {
      id
      name
      endDate
      startDate
      studentNoShowDays
      studentAssessmentDays
      cancellationDays
      engagement {
        id
        usHolidays
        noTutoringDays
        studentNoShowDays
        studentAssessmentDays
      }
    }
  `,
};

type Props = {
  readOnly?: boolean;
  className?: string;
  hideEmpty?: boolean;
  heightMode?: HeightMode;
  cohort: CohortSpecialDaysCard_CohortFragment;
};

export function CohortSpecialDaysCard({
  cohort,
  className,
  readOnly = false,
  hideEmpty = false,
  heightMode = HeightMode.Fit,
}: Props) {
  const { CohortNoShow } = SpecialDayType;
  const { storeBoolean, getBoolean } = useUIState();
  const [hasCheckedDays, setHasCheckedDays] = useState(false);
  const [hasSpecialDays, setHasSpecialDays] = useState(false);
  const [rowStyles, setRowStyles] = useState<DetailsRowStyles>();
  const specialDaysID = `${COHORT_SPECIAL_DAYS_KEY}:${cohort.id}`;
  const [specialDayType, setSpecialDayType] = useState(CohortNoShow);
  const [showSpecialDaysModal, setShowSpecialDaysModal] = useState(false);

  const { engagement, studentAssessmentDays } = cohort;
  const { studentNoShowDays: engNoShowDays } = engagement;
  const { studentAssessmentDays: engAssessmentDays } = engagement;

  const specialDaysMap = useMemo(
    () =>
      new Map<SpecialDayType, Date[]>([
        [SpecialDayType.EngagementNoShow, normalize(engNoShowDays)],
        [SpecialDayType.CohortNoShow, normalize(cohort.studentNoShowDays)],
        [SpecialDayType.EngagementAssessment, normalize(engAssessmentDays)],
        [SpecialDayType.CohortAssessment, normalize(studentAssessmentDays)],
        [SpecialDayType.NoTutoring, normalize(engagement.noTutoringDays)],
        [SpecialDayType.USHoliday, normalize(cohort.engagement.usHolidays)],
        [SpecialDayType.Cancelled, normalize(cohort.cancellationDays)],
      ]),
    [
      cohort,
      engagement,
      engNoShowDays,
      engAssessmentDays,
      studentAssessmentDays,
    ]
  );

  useEffect(() => {
    // Broadcast the card's existence externally for layout purposes.
    if (!hasCheckedDays || !specialDaysMap) storeBoolean(specialDaysID, true);

    const details = getSpecialDaysDetails(specialDaysMap, hideEmpty);
    setRowStyles(details.rowStyles);
    setHasSpecialDays(details.hasDays);
    setHasCheckedDays(true);

    const showCardChanged = getBoolean(specialDaysID) !== details.hasDays;
    if (showCardChanged) storeBoolean(specialDaysID, details.hasDays);
  }, [
    hideEmpty,
    getBoolean,
    storeBoolean,
    specialDaysID,
    hasCheckedDays,
    specialDaysMap,
  ]);

  const { primarySpecialDays, secondarySpecialDays } = useMemo(() => {
    const { primaryCohortType, secondaryCohortType } =
      daysDetails(specialDayType);

    return {
      primarySpecialDays: specialDaysMap.get(primaryCohortType),
      secondarySpecialDays: specialDaysMap.get(secondaryCohortType),
    };
  }, [specialDaysMap, specialDayType]);

  const openModalHandler = (type: SpecialDayType) => {
    if (!readOnly && daysDetails(type).isCohortTypeDay)
      return () => {
        setSpecialDayType(type);
        setShowSpecialDaysModal(true);
      };
  };

  // If there's no special days——or we're not sure yet——return null
  return !hideEmpty || (hasCheckedDays && hasSpecialDays) ? (
    <>
      <Card
        id={specialDaysID}
        icon="calendarDays"
        header="Special Days"
        className={className}
        rowStyles={rowStyles}
        heightMode={heightMode}
        headerOverlayColor={HeaderColors.Rose}
        rows={Array.from(specialDaysMap)
          .filter(([_, days]) => !hideEmpty || days.length > 0)
          .map(([specialDayType, days]) => [
            <SpecialDaysTooltip key={0} specialDayType={specialDayType} />,
            <SpecialDaysScrollContainer
              key={0}
              specialDays={days}
              maxHeight="max-h-[112px]"
              noDaysMessage={`No ${specialDayType}`}
              onClick={openModalHandler(specialDayType)}
            />,
          ])}
      />

      <SpecialDaysModal
        entityId={cohort.id}
        endDate={cohort.endDate}
        entityName={cohort.name}
        startDate={cohort.startDate}
        primarySpecialDayType={specialDayType}
        refetchQueries={["CohortDetailsPage"]}
        show={showSpecialDaysModal && !readOnly}
        secondarySpecialDays={secondarySpecialDays}
        primarySpecialDays={primarySpecialDays ?? []}
        closeModal={() => setShowSpecialDaysModal(false)}
      />
    </>
  ) : null;
}

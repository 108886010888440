import { DetailsRowStyles } from "types/global";
import { SpecialDayTitleWidths } from "./constants";
import { SpecialDayType } from "./types";

export const getSpecialDaysModalSubtitle = (
  specialDayType: SpecialDayType,
  entityName: string
) => {
  const { isCohortEntity, secondaryCohortType } = daysDetails(specialDayType);

  return isCohortEntity
    ? `${entityName}'s ${specialDayType} are shown in blue, while its ${secondaryCohortType}
      are shown in purple.`
    : `${entityName}'s ${specialDayType} are shown in blue.`;
};

export const daysDetails = (dayType: SpecialDayType) => ({
  isNoShowDays:
    dayType === SpecialDayType.CohortNoShow ||
    dayType === SpecialDayType.EngagementNoShow,
  isCohortEntity:
    dayType === SpecialDayType.CohortNoShow ||
    dayType === SpecialDayType.CohortAssessment,
  isEngagementTypeDay:
    dayType === SpecialDayType.EngagementNoShow ||
    dayType === SpecialDayType.EngagementAssessment,
  isCohortTypeDay:
    dayType === SpecialDayType.CohortNoShow ||
    dayType === SpecialDayType.CohortAssessment,
  primaryCohortType:
    dayType === SpecialDayType.CohortNoShow
      ? SpecialDayType.CohortNoShow
      : SpecialDayType.CohortAssessment,
  secondaryCohortType:
    dayType === SpecialDayType.CohortNoShow
      ? SpecialDayType.EngagementNoShow
      : SpecialDayType.EngagementAssessment,
  primaryEngagementType:
    dayType === SpecialDayType.EngagementNoShow
      ? SpecialDayType.EngagementNoShow
      : SpecialDayType.EngagementAssessment,
});

export const getSpecialDaysDetails = (
  specialDaysMap: Map<SpecialDayType, Date[]>,
  hideEmpty: boolean
): {
  hasDays: boolean;
  rowStyles: DetailsRowStyles;
} => {
  const allSpecialDays = Array.from(specialDaysMap);
  const typesWithDays = allSpecialDays.filter(([_, dates]) => dates.length > 0);
  const titles = hideEmpty ? typesWithDays : allSpecialDays;
  const widths = titles.map(([type]) => SpecialDayTitleWidths[type]);
  const titleWidth = Math.max(...widths);

  return {
    hasDays: typesWithDays.length > 0,
    rowStyles: [
      {
        width: `${titleWidth}px`,
        minWidth: `${titleWidth}px`,
      },
      { minWidth: `230px` },
    ],
  };
};

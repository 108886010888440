import {
  CohortAssignmentSubject,
  CohortAssignmentSubSubject,
  GradeLevel,
} from "@generated/graphql";
import { makeKey } from "@utils/strings";
import { clsx } from "clsx";
import { SubjectGradeLevelsBadge } from "./SubjectGradeLevelsBadge";
import { getSubjectGradeBadgesFromEvents } from "./utils";

type Props = {
  className?: string;
  cohort: {
    instructionLevel: GradeLevel;
    events: {
      cohortSubject: CohortAssignmentSubject;
      cohortSubSubject: CohortAssignmentSubSubject;
    }[];
  };
};

export const SubjectGradeLevelsBadges = ({ cohort, className }: Props) => (
  <div className={clsx("flex items-center gap-[6px]", className)}>
    {getSubjectGradeBadgesFromEvents(cohort).map((sgl) => (
      <SubjectGradeLevelsBadge
        subject={sgl.subject}
        gradeLevels={sgl.gradeLevels}
        key={makeKey(sgl.subject, sgl.subSubject || "")}
      />
    ))}
  </div>
);

import { gql } from "@apollo/client";
import { useAuth } from "@contexts/AuthProvider";
import { CohortDetailsCard_CohortFragment, Language } from "@generated/graphql";
import { CopyText, GradeLevelBadge } from "@shared";
import { SubjectGradeLevelsBadges } from "@shared/Badges/SubjectGradeLevelsBadge/SubjectGradeLevelsBadges";
import { getLanguageText } from "@utils/labels";
import { Routes } from "@utils/routes";
import { EditCohortModal } from "components/cohorts/modals/AddEditModals";
import { Link } from "components/shared";
import {
  Card,
  getEditModalOpenHeader,
  HeaderColors,
  HeightMode,
} from "components/shared/Card";
import { getNormalizedShortFullDateRangeText } from "helpers";
import { useState } from "react";
import { DetailsRow } from "types/global";

CohortDetailsCard.fragments = {
  cohort: gql`
    fragment CohortDetailsCard_Cohort on Cohort {
      id
      name
      endDate
      language
      startDate
      hourlyTTRate
      instructionLevel
      mentorTeachers {
        id
        name
      }
      engagement {
        id
        name
        hourlyTTRate
        organization {
          id
          name
        }
      }
      events {
        id
        cohortSubject
        cohortSubSubject
      }
    }
  `,
};

type Props = {
  className?: string;
  heightMode?: HeightMode;
  cohort: CohortDetailsCard_CohortFragment;
};

export function CohortDetailsCard({
  cohort,
  className,
  heightMode = HeightMode.Fit,
}: Props) {
  const { isAdmin, user } = useAuth();
  const includedRows = getRoleRows(isAdmin, user);
  const [showModal, setShowModal] = useState(false);

  const { engagement, instructionLevel, language } = cohort;
  const { organization, id: engId, name: engName } = engagement;
  const { id: orgId, name: orgName } = organization;

  const rows = [
    ["Name", cohort.name],
    [
      "Organization",
      <Link key="Organization" route={Routes.org.details} routeProps={[orgId]}>
        {orgName}
      </Link>,
    ],
    [
      "Engagement",
      <Link
        key={"Engagement"}
        disabled={!isAdmin}
        routeProps={[engId]}
        customColor={!isAdmin}
        route={Routes.engagement.details}
      >
        {engName}
      </Link>,
    ],
    ["Cohort ID", <CopyText key={0} text={cohort.id} />],
    [
      "Dates",
      getNormalizedShortFullDateRangeText(cohort.startDate, cohort.endDate),
    ],
    ["Language", getLanguageText(language ?? Language.English)],
    [
      "Instruction Level",
      <GradeLevelBadge
        key="Instruction Level"
        gradeLevels={[instructionLevel]}
      />,
    ],
    ["Classes", <SubjectGradeLevelsBadges key="Classes" cohort={cohort} />],
    [
      "TT Pay Rate",
      `$${(cohort.hourlyTTRate ?? engagement.hourlyTTRate).toString()}/Hour`,
    ],
    [
      "Mentor Teacher(s)",
      cohort.mentorTeachers.map((mt) => mt.name).join(", "),
    ],
  ].filter((row) => includedRows.includes(String(row[0]))) as DetailsRow[];

  return (
    <>
      <Card
        rows={rows}
        icon="details"
        className={className}
        heightMode={heightMode}
        id={`CohortDetailsCard:${cohort.id}`}
        headerOverlayColor={HeaderColors.Blue}
        rowStyles={[{ minWidth: "150px" }, {}]}
        header={
          isAdmin
            ? getEditModalOpenHeader("Details", () => setShowModal(true))
            : `Cohort Details`
        }
      />

      <EditCohortModal
        show={showModal}
        cohortId={cohort.id}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}

const getRoleRows = (
  isAdmin: boolean,
  isPaidExternally?: { isPaidExternally: boolean } | null
) => {
  return isAdmin
    ? [
        "Organization",
        "Engagement",
        "Cohort ID",
        "Public Reference ID",
        "Language",
        "Classes",
        "Instruction Level",
        "TT Pay Rate",
      ]
    : [
        "Name",
        "Engagement",
        "Instruction Level",
        "Language",
        ...(!isPaidExternally ? ["TT Pay Rate"] : []),
        "Dates",
        "Classes",
        "Mentor Teacher(s)",
      ];
};
